import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendPasswordResetEmail as sendResetEmail,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDYaMZrgRc5wYAPABWq_5uE26YD6g18N9Q",
  authDomain: "timecard-d0e57.firebaseapp.com",
  projectId: "timecard-d0e57",
  storageBucket: "timecard-d0e57.appspot.com",
  messagingSenderId: "709470177026",
  appId: "1:709470177026:web:8c8263bdc63e29fe0fa41b",
};

const app = initializeApp(firebaseConfig);
console.log("Firebase initialized"); // 追加

const auth = getAuth(app);
const db = getFirestore(app);

const sendPasswordResetEmail = (email) => {
  return sendResetEmail(auth, email);
};

export { auth, db, sendPasswordResetEmail };
