import React, { useState } from "react";
import { auth } from "./firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { db } from "./firebase";
import "./SignUp.css";

const SignUp = ({ onBackToLogin }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("パスワードが一致しません。");
      return;
    }
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await sendEmailVerification(userCredential.user);
      await setDoc(doc(db, "settings", userCredential.user.uid), {
        firstName,
        lastName,
        employeeId: "",
        defaultClockIn: "09:00",
        defaultClockOut: "18:00",
        breakStartTime: "12:00",
        breakDuration: "01:00",
        workTimeUnit: "1分",
        lastWorkType: "現場",
        treatEarlyClockInAsDefault: true,
      });
      setVerificationSent(true);
    } catch (error) {
      alert("新規登録に失敗しました: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  if (verificationSent) {
    return (
      <div className="signup-container">
        <h2>登録が完了しました</h2>
        <p>確認メールを送信しました。メールを確認してください。</p>
        <button onClick={handleRefresh}>メールを確認しました</button>
      </div>
    );
  }

  return (
    <div className="signup-container">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner">Loading...</div>
        </div>
      )}
      <form onSubmit={handleSubmit} className="signup-form">
        <h2>新規登録</h2>
        <div className="form-group">
          <label>お名前:</label>
          <div className="name-inputs">
            <input
              type="text"
              placeholder="姓"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="名"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Confirm Password:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "登録中..." : "登録"}
        </button>
        <div className="login-link">
          <span onClick={onBackToLogin}>ログイン画面に戻る</span>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
