import React, { useState } from "react";
import "./Login.css";

const Login = ({ onLogin, onSignUp, onPasswordReset, loading, error }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await onLogin(email, password);
    } catch (error) {}
  };

  const handlePasswordReset = () => {
    if (!email) {
      alert("パスワードリセットのためにメールアドレスを入力してください。");
      return;
    }
    onPasswordReset(email);
  };

  return (
    <div className="login-container">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner">
            <div className="spinner"></div>
            <div>ログイン中...</div>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit} className="login-form">
        <h2>ログイン</h2>
        {error && <p className="error-message">{error}</p>}{" "}
        {/* エラーメッセージを表示 */}
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "ログイン中..." : "ログイン"}
        </button>
        <div className="link-group">
          <span className="signup-link" onClick={onSignUp}>
            新規登録はこちら
          </span>
          <span className="password-reset-link" onClick={handlePasswordReset}>
            パスワードを忘れた場合
          </span>
        </div>
      </form>
    </div>
  );
};

export default Login;
